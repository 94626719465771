/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import S from './simpads.module.scss';
import axios from '../../../axios';
import AdminNavBar from '../components/admin-nav-bar';

function Simpads() {
    const [stationLookup, setStationLookup] = useState(new Map());
    const [simpads, setSimpads] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        await Promise.all([
            axios.get('/stations').then((allStationData) => {
                const stationNameLookup = new Map();
                allStationData.data.forEach((station) =>
                    stationNameLookup.set(station.station_id, station.name)
                );
                setStationLookup(stationNameLookup);
            }),
            axios.get('/simpads').then((allSimpadsData) => {
                setSimpads(orderBy(allSimpadsData.data, ['simpad_serial']));
            }),
        ]);

        setLoading(false);
        // ;
    }, []);

    const onDeleteSimpad = (simpadSerial) => {
        setSimpads((prevSimpads) =>
            prevSimpads.filter((c) => c.simpad_serial !== simpadSerial)
        );

        axios.delete('/simpads', {
            data: {
                simpadSerial,
            },
        });
    };

    return (
        <div className={S.layout}>
            <AdminNavBar tabIx={1} />
            <Typography variant="h3" className={S.header}>
                Simpads
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : (
                <div className={S.gridContainer}>
                    <div style={{ flexGrow: 1 }}>
                        <TableContainer component={Paper}>
                            <Table aria-label="Registered Simpads">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Simpad Serial Number
                                        </TableCell>
                                        <TableCell>Station</TableCell>
                                        <TableCell align="right" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {simpads.map((simpad) => (
                                        <TableRow key={simpad.simpad_serial}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {simpad.simpad_serial}
                                            </TableCell>
                                            <TableCell>
                                                {stationLookup.get(
                                                    simpad.station_id
                                                )}
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                width="48px"
                                            >
                                                <IconButton
                                                    onClick={() =>
                                                        onDeleteSimpad(
                                                            simpad.simpad_serial
                                                        )
                                                    }
                                                    aria-label="delete"
                                                    component="span"
                                                    className={S.deleteButton}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Simpads;
