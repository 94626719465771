import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import TrophyFirst from '../../assets/images/trophy-first.svg';
import TrophySecond from '../../assets/images/trophy-second.svg';
import TrophyThird from '../../assets/images/trophy-third.svg';

import S from '../../assets/styles/leaderboard.module.scss';

function LeaderBoardNoDataLabelCell({ loading, rank, width }) {
    let trophyImage = null;
    let teamCellContainerClasses = '';
    let loadingBarClasses = S.linearBackground;

    if (rank === 1) {
        trophyImage = TrophyFirst;
        teamCellContainerClasses = S.firstTeamCellContainer;
        loadingBarClasses = `${loadingBarClasses} ${S.linearBackgroundGold}`;
    } else if (rank === 2) {
        trophyImage = TrophySecond;
        teamCellContainerClasses = S.secondTeamCellContainer;
        loadingBarClasses = `${loadingBarClasses} ${S.linearBackgroundSilver}`;
    } else if (rank === 3) {
        trophyImage = TrophyThird;
        teamCellContainerClasses = S.thirdTeamCellContainer;
        loadingBarClasses = `${loadingBarClasses} ${S.linearBackgroundBronze}`;
    }

    return (
        <>
            <div className={`${S.normalCell} ${S.teamCell}`} style={{ width }}>
                <div
                    className={`${S.teamCellContainer} ${teamCellContainerClasses}`}
                >
                    <div className={S.rankContainer}>
                        {[1, 2, 3].includes(rank) ? (
                            <img src={trophyImage} alt={`Rank-${rank}`} />
                        ) : (
                            <Typography
                                variant="h6"
                                color="secondary"
                                className={S.cellText}
                            >
                                {rank}
                            </Typography>
                        )}
                    </div>
                    <div className={S.teamNameContainer}>
                        {loading ? (
                            <div className={S.loadingContainer}>
                                <div className={S.interLine}>
                                    <div className={loadingBarClasses} />
                                </div>
                            </div>
                        ) : (
                            <Typography
                                variant="h6"
                                color="secondary"
                                className={S.cellText}
                            >
                                --
                            </Typography>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

LeaderBoardNoDataLabelCell.propTypes = {
    loading: PropTypes.bool,
    rank: PropTypes.number,
    width: PropTypes.string,
};

LeaderBoardNoDataLabelCell.defaultProps = {
    loading: false,
    rank: 0,
    width: '54%',
};

export default LeaderBoardNoDataLabelCell;
