import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import GoldCup from '../../../../assets/images/gold-cup.svg';
import SilverCup from '../../../../assets/images/silver-cup.svg';
import BronzeCup from '../../../../assets/images/bronze-cup.svg';
import AWARD_LEVELS from '../../../../constants/award-levels';

import S from './award-chart.module.scss';

function AwardChart({ awardLevel, teamData }) {
    const [cupImage, setCupImage] = useState(null);
    const [bonus, setBonus] = useState(0);
    const [trophyContainerClasses, setTrophyContainerClasses] = useState('');
    const [towerClasses, setTowerClasses] = useState('');
    const [teamContainerClasses, setTeamContainerClasses] = useState('');
    const [regionClasses, setRegionClasses] = useState('');
    const [valueContainerClasses, setValueContainerClasses] = useState('');

    useEffect(() => {
        switch (awardLevel) {
            case AWARD_LEVELS[0]: {
                setCupImage(GoldCup);
                setBonus(3);
                setTrophyContainerClasses(S.goldTrophyContainer);
                setTowerClasses(S.goldTower);
                setTeamContainerClasses(S.goldTeamContainer);
                setRegionClasses(S.goldRegion);
                setValueContainerClasses(S.goldValueContainer);
                break;
            }
            case AWARD_LEVELS[1]: {
                setCupImage(SilverCup);
                setBonus(2);
                setTrophyContainerClasses(S.silverTrophyContainer);
                setTowerClasses(S.silverTower);
                setTeamContainerClasses(S.silverTeamContainer);
                setRegionClasses(S.silverRegion);
                setValueContainerClasses(S.silverValueContainer);
                break;
            }
            default: {
                setCupImage(BronzeCup);
                setBonus(1);
                setTrophyContainerClasses(S.bronzeTrophyContainer);
                setTowerClasses(S.bronzeTower);
                setTeamContainerClasses(S.bronzeTeamContainer);
                setRegionClasses(S.bronzeRegion);
                setValueContainerClasses(S.bronzeValueContainer);
            }
        }
    }, [awardLevel]);

    return (
        <Container className={S.container}>
            <Grid
                container
                className={`${S.trophyContainer} ${trophyContainerClasses}`}
            >
                <img src={cupImage} alt={awardLevel} />
            </Grid>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                className={`${S.tower} ${towerClasses}`}
            >
                <Grid
                    container
                    direction="column"
                    className={S.teamAndZoneContainer}
                >
                    {teamData.map((teamDataMap) => (
                        <div key={teamDataMap.team}>
                            <Grid
                                container
                                alignItems="center"
                                justifyContent="center"
                                className={`${S.teamContainer} ${teamContainerClasses}`}
                            >
                                <Typography className={S.teamTitle}>
                                    {teamDataMap.team}
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justifyContent="center"
                                className={`${S.region} ${regionClasses}`}
                            >
                                {`${
                                    teamDataMap.station === null
                                        ? '(NO STATION)'
                                        : teamDataMap.station
                                }, 
                                ${
                                    teamDataMap.zone === null
                                        ? '(NO ZONE)'
                                        : teamDataMap.zone
                                }`}
                            </Grid>
                        </div>
                    ))}
                </Grid>
                <Grid container alignItems="flex-end" justifyContent="center">
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        spacing={2}
                        wrap="nowrap"
                        className={S.scoreContainer}
                    >
                        <Grid item>
                            <Typography align="center" className={S.itemTitle}>
                                CPR
                            </Typography>
                            <Grid
                                container
                                justifyContent="center"
                                className={`${S.valueContainer} ${valueContainerClasses}`}
                            >
                                <Typography className={S.itemValue}>
                                    {formatCprScore(teamData[0].cpr)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography align="center" className={S.itemTitle}>
                                CCF
                            </Typography>
                            <Grid
                                container
                                justifyContent="center"
                                className={`${S.valueContainer} ${valueContainerClasses}`}
                            >
                                <Typography className={S.itemValue}>
                                    {formatCcf(teamData[0].ccf)}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Typography align="center" className={S.itemTitle}>
                                Total
                            </Typography>
                            <Grid
                                container
                                justifyContent="center"
                                className={`${S.valueContainer} ${valueContainerClasses}`}
                            >
                                <Typography className={S.itemValue}>
                                    {formatTotal(
                                        teamData[0].cpr,
                                        teamData[0].ccf
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className={S.bonusButton}
                    >
                        <AddIcon />
                        <Typography className={S.buttonTitle}>
                            {bonus} bonus
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

AwardChart.propTypes = {
    awardLevel: PropTypes.oneOf(AWARD_LEVELS),
    teamData: PropTypes.arrayOf(
        PropTypes.shape({
            team: PropTypes.string,
            station: PropTypes.string,
            zone: PropTypes.string,
            cpr: PropTypes.string,
            ccf: PropTypes.string,
        })
    ),
};

AwardChart.defaultProps = {
    awardLevel: '',
    teamData: [
        {
            team: '--, --',
            station: '--',
            zone: '--',
            cpr: null,
            ccf: null,
        },
    ],
};

export default AwardChart;

/**
 * Pretty print a daily best score
 * @param {string} score String representation of score sent from the backend
 * @returns {string} Pretty score
 */
function formatCprScore(score) {
    if (score == null || score === '') {
        return '--';
    }

    return Math.round(parseFloat(score));
}

/**
 * Pretty print a daily best score
 * @param {string} score String representation of score sent from the backend
 * @returns {string} Pretty score
 */
function formatCcf(score) {
    if (score == null || score === '') {
        return '--';
    }

    return `${Math.round(parseFloat(score))}%`;
}

/**
 * Pretty print a daily best total score
 * @param {string} cpr String representation of cpr score sent from the backend
 * @param {string} ccf String representation of ccf score sent from the backend
 * @returns {string} Pretty score
 */
function formatTotal(cpr, ccf) {
    if (cpr == null || cpr === '') {
        return '--';
    }
    if (ccf == null || ccf === '') {
        return '--';
    }

    return Math.round(parseFloat(cpr) + parseFloat(ccf));
}
