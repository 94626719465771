import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import CircularProgress from '@material-ui/core/CircularProgress';
import S from './App.module.scss';

import theme from './assets/styles/theme';
import NavHeader from './components/nav-header';
import Login from './views/login';
import TopDistricts from './views/top-districts';
import TopStations from './views/top-stations';
import TopTeams from './views/top-teams';
import DailyBest from './views/daily-best';
import SimpadCodes from './views/admin/simpad-codes';
import Sessions from './views/admin/sessions';
import Simpads from './views/admin/simpads';
import Superadmin from './views/admin/superadmin';
import Students from './views/admin/students';
import axios from './axios';

import { setUser } from './reducers/session-reducer';
import StudentBulkLanding from './views/admin/student-bulk-import/student-bulk-landing';

let spinnerTimeout;

function App() {
    const loggedInUser = useSelector((state) => state.session.user);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(
        // If this user has never logged in don't bother going to the backend
        !(Cookies.get('initialLogin') == null)
    );

    const [showSpinner, setShowSpinner] = useState(false);

    useEffect(async () => {
        if (loading) {
            // The page loads so fast most of the time it's not even worth showing the spinner normally
            spinnerTimeout = setTimeout(() => setShowSpinner(true), 500);
            const result = await axios.get('/auth/refresh');
            dispatch(setUser(result.data));
            setLoading(false);
            clearTimeout(spinnerTimeout);
        }
    }, []);

    if (loading) {
        return (
            <div>
                <NavHeader />
                {showSpinner && (
                    <div className={S.loadingSpinner}>
                        <CircularProgress size={100} />
                    </div>
                )}
            </div>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <div className={S.app}>
                {!loggedInUser ? (
                    <Switch>
                        <Route path="/login" component={Login} />
                        <Redirect
                            to={{
                                pathname: '/login',
                                state: { referrer: useLocation() },
                            }}
                        />
                    </Switch>
                ) : (
                    <>
                        <NavHeader />
                        <Switch>
                            <Redirect exact from="/login" to="/" />
                            <Redirect exact from="/" to="/top-zones" />

                            <Route path="/top-zones" component={TopDistricts} />
                            <Route
                                path="/top-stations"
                                component={TopStations}
                            />
                            <Route path="/top-teams" component={TopTeams} />
                            <Route path="/daily-best" component={DailyBest} />
                            <Redirect
                                exact
                                from="/admin"
                                to="/admin/sessions"
                            />
                            <Route
                                path="/admin/codes"
                                component={SimpadCodes}
                            />
                            <Route
                                path="/admin/sessions"
                                component={Sessions}
                            />
                            <Route path="/admin/simpads" component={Simpads} />
                            <Route path="/admin/users" component={Superadmin} />
                            <Route
                                path="/admin/students/bulk-import"
                                component={StudentBulkLanding}
                            />
                            <Route
                                path="/admin/students"
                                component={Students}
                            />
                        </Switch>
                    </>
                )}
            </div>
        </ThemeProvider>
    );
}

export default App;
