import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import Team from '../../models/team';
import LeaderBoardLabelCell from '../leaderboard-label-cell';
import LeaderBoardBasicCell from '../leaderboard-basic-cell';
import LeaderBoardTotalCell from '../leaderboard-total-cell';
import LeaderBoardNoDataLabelCell from '../leaderboard-no-data-label-cell';
import LeaderBoardAlertIcon from '../leaderboard-alert-icon';

import S from '../../assets/styles/leaderboard.module.scss';

function LeaderBoardTeamRow({ loading, teamData, hasBorder, onHover }) {
    const handleMouseOver = () => {
        if (onHover) {
            onHover(teamData);
        }
    };

    const handleMouseLeave = () => {
        if (onHover) {
            onHover();
        }
    };

    return (
        <>
            {teamData && (
                <Grid
                    container
                    direction="row"
                    className={S.tableRowContainer}
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className={S.tableRow}>
                        {teamData.total ? (
                            <LeaderBoardLabelCell
                                loading={loading}
                                rank={teamData.rank}
                                value={teamData.team}
                            />
                        ) : (
                            <LeaderBoardNoDataLabelCell
                                loading={loading}
                                rank={teamData.rank}
                            />
                        )}
                        <LeaderBoardBasicCell
                            value={teamData.cpr}
                            hasBorder={hasBorder}
                        />
                        <LeaderBoardBasicCell
                            value={teamData.ccf}
                            hasBorder={hasBorder}
                        />
                        <LeaderBoardBasicCell
                            value={
                                teamData.bonus
                                    ? parseInt(teamData.bonus, 10)
                                    : null
                            }
                            isBonus
                            hasBorder={hasBorder}
                        />
                        <LeaderBoardTotalCell
                            rank={teamData.rank}
                            value={teamData.total}
                            hasBorder={hasBorder}
                        />
                        {teamData.total >= 0 && !loading ? (
                            <LeaderBoardAlertIcon
                                numAttempts={teamData.session_count}
                            />
                        ) : (
                            <div className={`${S.normalCell} ${S.alert}`} />
                        )}
                    </div>
                </Grid>
            )}
        </>
    );
}

LeaderBoardTeamRow.propTypes = {
    loading: PropTypes.bool,
    teamData: PropTypes.shape(Team),
    hasBorder: PropTypes.bool,
    onHover: PropTypes.func,
};

LeaderBoardTeamRow.defaultProps = {
    loading: false,
    teamData: null,
    hasBorder: true,
    onHover: null,
};

export default LeaderBoardTeamRow;
