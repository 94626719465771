import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import S from '../../assets/styles/leaderboard.module.scss';

function LeaderBoardBasicCell({ value, isBonus, hasBorder }) {
    return (
        <div
            className={`${S.normalCell}
                ${hasBorder ? S.borderCell : ''}`}
        >
            <div className={isBonus ? S.bonusContainer : S.cprContainer}>
                <Typography
                    variant="h6"
                    color="secondary"
                    className={S.cellText}
                >
                    {`${isBonus && value > 0 ? '+' : ''}${
                        value ? value.toFixed(isBonus ? 0 : 1) : '--'
                    }`}
                </Typography>
            </div>
        </div>
    );
}

LeaderBoardBasicCell.propTypes = {
    value: PropTypes.number,
    isBonus: PropTypes.bool,
    hasBorder: PropTypes.bool,
};

LeaderBoardBasicCell.defaultProps = {
    value: null,
    isBonus: false,
    hasBorder: true,
};

export default LeaderBoardBasicCell;
