import React from 'react';

export default function LogoDivider() {
    return (
        <div style={{ padding: '0 10px' }}>
            <svg
                width="2"
                height="24"
                viewBox="0 0 1 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <line x1="0.5" x2="0.5" y2="24" stroke="#1F1F1F" />
            </svg>
        </div>
    );
}
