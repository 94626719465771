import { createSlice } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

const cookieOptions = Object.freeze({
    sameSite: 'strict',
    secure: process.env.NODE_ENV === 'production',
});

const sessionSlice = createSlice({
    name: 'session',
    initialState: {},
    reducers: {
        setUser(state, action) {
            Cookies.set('initialLogin', new Date().getTime(), {
                expires: 365,
                ...cookieOptions,
            });
            // eslint-disable-next-line no-param-reassign
            state.user = action.payload;
        },
        logout(state) {
            Cookies.remove('initialLogin', cookieOptions);
            // eslint-disable-next-line no-param-reassign
            state.user = null;
        },
    },
});

const { actions, reducer } = sessionSlice;

export const { setUser, logout } = actions;

export default reducer;
