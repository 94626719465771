import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/AddCircleOutline';

import S from '../../assets/styles/leaderboard.module.scss';

function LeaderBoardTeamHeaderRow() {
    return (
        <Grid container direction="row">
            <div
                className={`${S.normalCell} ${S.headerCell} ${S.teamLabelHeaderCell}`}
            >
                <Typography
                    variant="h6"
                    color="secondary"
                    className={S.tableHeaderText}
                >
                    TOP 10 PERFORMING <b>TEAMS</b>
                </Typography>
            </div>
            <div className={`${S.normalCell} ${S.headerCell}`}>
                <Typography
                    variant="h6"
                    color="secondary"
                    className={S.tableHeaderText}
                >
                    <b>CPR</b>
                </Typography>
            </div>

            <div className={`${S.normalCell} ${S.headerCell}`}>
                <Typography
                    variant="h6"
                    color="secondary"
                    className={S.tableHeaderText}
                >
                    <b>CCF</b>
                </Typography>
            </div>
            <div className={`${S.normalCell} ${S.headerCell}`}>
                <AddIcon fontSize="small" />
            </div>
            <div className={`${S.normalCell} ${S.headerCell}`}>
                <Typography
                    variant="h6"
                    color="secondary"
                    className={S.tableHeaderText}
                >
                    <b>Total</b>
                </Typography>
            </div>
        </Grid>
    );
}

export default LeaderBoardTeamHeaderRow;
