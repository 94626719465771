import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { StylesProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { datadogRum } from '@datadog/browser-rum';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, history } from './reducers/reducers';
import pkg from '../package.json';

if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-console
    console.log(`${pkg.version}-${process.env.REACT_APP_GITHUB_SHA}`);
    datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'cpr-competition',
        version: pkg.version,
        sampleRate: 100,
        trackInteractions: true,
        defaultPrivacyLevel: 'mask-user-input',
        allowedTracingOrigins: [process.env.API_ROOT],
        env: process.env.REACT_APP_DATADOG_ENV,
    });

    datadogRum.startSessionReplayRecording();
}

ReactDOM.render(
    <Provider store={store}>
        <StylesProvider injectFirst>
            <ConnectedRouter history={history}>
                {' '}
                {/* place ConnectedRouter under Provider */}
                <App />
            </ConnectedRouter>
        </StylesProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
