const METRO_ZONES = [
    'Nepean Blue Mountains Zone',
    'Western Sydney Zone',
    'Central Coast Zone',
    'North Sydney Zone',
    'South West Sydney Zone 2',
    'Sydney Zone',
    'South West Sydney Zone 1',
    'South Eastern Sydney Zone',
];

export default METRO_ZONES;
