import { combineReducers, applyMiddleware, compose, createStore } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import SessionReducer from './session-reducer';

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        session: SessionReducer,
    });

export const history = createBrowserHistory();

export const store = createStore(
    createRootReducer(history), // root reducer with router state
    undefined,
    compose(
        applyMiddleware(
            routerMiddleware(history) // for dispatching history actions
            // ... other middlewares ...
        )
    )
);
