import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import PropTypes from 'prop-types';
import Logout from '@material-ui/icons/ExitToApp';
import { useDispatch } from 'react-redux';
import NavHeaderLogo from './nav-header-logo';
import { logout } from '../../reducers/session-reducer';

import S from './nav-header.module.scss';

function NavHeader() {
    const location = useLocation();
    const currentPath = location.pathname;
    const dispatch = useDispatch();

    const logoutFunc = () => {
        dispatch(logout());
    };

    return (
        <AppBar position="static" color="inherit">
            <Toolbar>
                <NavHeaderLogo />
                <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    spacing={1}
                    className={S.actionsContainer}
                >
                    <Grid item>
                        <NavButton
                            to="/top-zones"
                            text="Top Zones"
                            selected={currentPath === '/top-zones'}
                        />
                    </Grid>
                    <Grid item>
                        <NavButton
                            to="/top-stations"
                            text="Top Stations"
                            selected={currentPath === '/top-stations'}
                        />
                    </Grid>
                    <Grid item>
                        <NavButton
                            to="/top-teams"
                            text="Top Teams"
                            selected={currentPath === '/top-teams'}
                        />
                    </Grid>
                    <Grid item>
                        <NavButton
                            to="/daily-best"
                            text="Daily Best"
                            selected={currentPath === '/daily-best'}
                        />
                    </Grid>

                    <Grid className={S.signOutContainer} item>
                        <ButtonBase
                            className={S.signOutButton}
                            onClick={logoutFunc}
                        >
                            <Logout />
                            <Typography>Log out</Typography>
                        </ButtonBase>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    );
}

function NavButton({ to, text, selected }) {
    return (
        <Button
            color="primary"
            variant="outlined"
            to={to}
            component={Link}
            className={selected ? S.selectedButton : S.button}
        >
            {text}
        </Button>
    );
}

NavButton.propTypes = {
    to: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
};

export default NavHeader;
