import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#24315B',
        },
        secondary: {
            main: '#1F1F1F',
        },
        gold: {
            main: '#EAC785',
            light: '#F8E2BF',
            dark: '#B38F00',
            contrastText: '#7A6200',
        },
        silver: {
            main: '#C5BEBA',
            light: '#E5E5E5',
            dark: '#767676',
            contrastText: '#4D4D4D',
        },
        bronze: {
            main: '#D5AB86',
            light: '#E3C6AB',
            dark: '#9A7257',
            contrastText: '#65482F',
        },
        black: {
            main: '#1F1F1F',
            light: '#333333',
        },
    },
});

export default theme;
