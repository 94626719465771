import { number, string } from 'prop-types';

const Team = {
    team_id: string,
    team: string,
    cpr: number,
    ccf: number,
    bonus: string,
    total: number,
    coordinates: string,
    station: string,
    zone_id: string,
    zone_name: string,
    rank: number,
};

export default Team;
