import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import centerOfMass from '@turf/center-of-mass';
import bbox from '@turf/bbox';
import mapboxgl from 'mapbox-gl';

import S from './top-districts.module.scss';
import axios from '../../axios';
import nswZones from '../../data/nsw_geography.json';
import METRO_ZONES from '../../constants/metro-zones';
import GoldCup from '../../assets/images/gold-marker.svg';
import SilverCup from '../../assets/images/silver-marker.svg';
import BronzeCup from '../../assets/images/bronze-marker.svg';

mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN;

const classNames = [S.gold, S.silver, S.bronze];

function TopDistricts() {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const lng = 147.644632;
    const lat = -33.329609;
    const zoom = 5;
    const [metroZones, setMetroZones] = useState([]);
    const [zonelessScore, setZonelessScore] = useState(null);

    const redStripesImageUrl = `${process.env.PUBLIC_URL}/diagonal-lines-vector-pattern-red.png`;
    const stripesImageUrl = `${process.env.PUBLIC_URL}/diagonal-lines-vector-pattern.jpg`;

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/brianciomei/ckq1owper47dv17lfv5sfzp25',
            center: [lng, lat],
            zoom,
        });

        const cm = map.current;

        window.addEventListener('resize', () => {
            cm.fitBounds(bbox(nswZones), {
                padding: 50,
            });
        });

        cm.on('load', async () => {
            // SVGs are not supported :(.
            // We should load this image directly from the module system
            // but I couldn't get it working
            const images = [
                {
                    id: 'station',
                    url: `${process.env.PUBLIC_URL}/station-polygon.png`,
                },
                {
                    id: 'gold',
                    url: `${process.env.PUBLIC_URL}/gold-marker.png`,
                },
                {
                    id: 'silver',
                    url: `${process.env.PUBLIC_URL}/silver-marker.png`,
                },
                {
                    id: 'bronze',
                    url: `${process.env.PUBLIC_URL}/bronze-marker.png`,
                },
                {
                    id: 'stripes',
                    url: stripesImageUrl,
                },
                {
                    id: 'stripes-red',
                    url: redStripesImageUrl,
                },
            ];

            let promises = [];
            promises.push(axios.get('/stations'));
            promises.push(axios.get('/zones/scores'));

            promises = promises.concat(
                images.map(
                    (img) =>
                        new Promise((resolve) => {
                            cm.loadImage(img.url, (error, res) => {
                                cm.addImage(img.id, res);
                                resolve();
                            });
                        })
                )
            );

            promises.push(
                cm.loadImage(
                    `${process.env.PUBLIC_URL}/map_tooltip.png`,
                    (error, res) => {
                        cm.addImage('popup', res, {
                            stretchX: [
                                [6, 39],
                                [55, 88],
                            ],
                            stretchY: [[5, 54]],
                            content: [12, 10, 82, 54],
                            pixelRatio: 2,
                        });
                    }
                )
            );

            promises.push(
                cm.loadImage(
                    `${process.env.PUBLIC_URL}/map_tooltip_light.png`,
                    (error, res) => {
                        cm.addImage('popup_light', res, {
                            stretchX: [
                                [6, 39],
                                [55, 88],
                            ],
                            stretchY: [[5, 54]],
                            content: [12, 10, 82, 54],
                            pixelRatio: 2,
                        });
                    }
                )
            );

            Promise.all(promises).then((values) => {
                let stations = [];
                let zoneScores = [];
                if (values && values[0]) {
                    stations = values[0].data || [];
                }
                if (values && values[1]) {
                    zoneScores = values[1].data || [];
                }
                const points = stations.map((s) => {
                    const point = JSON.parse(s.coordinates);
                    return {
                        type: 'Feature',
                        geometry: point,
                        properties: {
                            stationName: s.name,
                            zoneId: s.zone_id,
                        },
                    };
                });

                cm.addSource('stations', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: points,
                    },
                });

                zoneScores = reverse(
                    sortBy(
                        zoneScores,
                        (zs) => zs.score || Number.NEGATIVE_INFINITY
                    )
                );

                zoneScores.forEach((zs, index) => {
                    const zoneScore = zs;
                    zoneScore.rank = index + 1;
                });

                setZonelessScore(zoneScores.find((zs) => zs.zone === null));

                const zoneScoresLookup = new Map(
                    zoneScores
                        .filter((zs) => zs.zone != null)
                        .map((zs) => [zs.zone.name, zs])
                );

                cm.fitBounds(bbox(nswZones), {
                    padding: 50,
                });

                // Names are unique and constant, the UUIDs are generated everytime
                // TODO: Should probably update this geojson to be by uuid
                const zones = [];
                nswZones.features.forEach((z) => {
                    const zone = z;
                    const zoneScore = zoneScoresLookup.get(
                        zone.properties.name
                    );

                    if (zoneScore) {
                        zone.id = zoneScore.zone.zone_id;
                        zone.properties.rank = zoneScore.rank;
                        zone.properties.zoneId = zoneScore.zone.zone_id;
                        zones.push({
                            type: 'Feature',
                            geometry: centerOfMass(zone.geometry).geometry,
                            properties: {
                                id: zoneScore.zone.zone_id,
                                zoneName: zoneScore.zone.name,
                                rank: zoneScore.rank,
                                score: (zoneScore.score || 0).toFixed(1),
                            },
                        });
                    } else {
                        zones.push({
                            type: 'Feature',
                            geometry: centerOfMass(zone.geometry).geometry,
                            properties: {
                                id: zone.properties.name,
                                zoneName: zone.properties.name,
                                rank: -1,
                                score: 0,
                            },
                        });
                    }
                    zone.id = zone.properties.name;
                });

                const metroZoneLookup = new Set(METRO_ZONES);
                setMetroZones(
                    zoneScores.filter(
                        (zs) =>
                            zs.zone != null && metroZoneLookup.has(zs.zone.name)
                    )
                );

                cm.addSource('zones', {
                    type: 'geojson',
                    data: nswZones,
                });

                cm.addSource('refactored_zones', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: zones,
                    },
                });

                // Zones
                cm.addLayer({
                    id: 'zones_fill',
                    type: 'fill',
                    source: 'zones', // reference the data source
                    layout: {},
                    paint: {
                        'fill-color': [
                            'case',
                            ['==', ['get', 'rank'], 1],
                            '#EAC785',
                            ['==', ['get', 'rank'], 2],
                            '#C5BEBA',
                            ['==', ['get', 'rank'], 3],
                            '#D5AB86',
                            '#FFFFFF',
                        ],
                        'fill-opacity': 1,
                    },
                });

                // Zones
                cm.addLayer({
                    id: 'act_fill',
                    type: 'fill',
                    source: 'zones', // reference the data source
                    layout: {},
                    paint: {
                        'fill-opacity': 1,
                        'fill-pattern': 'stripes-red',
                    },
                    filter: ['==', ['get', 'name'], '!ACT!'],
                });

                // Fill metro
                cm.addLayer({
                    id: 'metro_fill',
                    type: 'fill',
                    source: 'zones', // reference the data source
                    layout: {},
                    paint: {
                        'fill-opacity': 1,
                        'fill-pattern': 'stripes',
                    },
                    filter: ['==', ['get', 'name'], '!Metropolitan!'],
                });

                // Add a black outline around the polygon.
                cm.addLayer({
                    id: 'zones_outline',
                    type: 'line',
                    source: 'zones',
                    layout: {},
                    paint: {
                        'line-color': '#000',
                        'line-width': 2,
                    },
                });

                // Top Districts
                // Name Label
                cm.addLayer({
                    id: 'zones_name',
                    type: 'symbol',
                    source: 'refactored_zones',
                    layout: {
                        'text-field': ['get', 'zoneName'],
                        'text-variable-anchor': ['top'],
                        'text-radial-offset': 2,
                        'text-font': ['Lato Bold'],
                        'text-justify': 'auto',
                    },
                    paint: {
                        'text-color': '#000000',
                        'text-halo-color': '#fff',
                        'text-halo-width': 2,
                        'text-halo-blur': 1,
                    },
                    filter: [
                        'all',
                        ['!=', 'zoneName', '!ACT!'],
                        ['!=', 'zoneName', '!Metropolitan!'],
                    ],
                });

                // Top 10 Scores Label
                cm.addLayer({
                    id: 'zones_score',
                    type: 'symbol',
                    source: 'refactored_zones',
                    layout: {
                        'text-field': ['get', 'score'],
                        'icon-text-fit': 'both',
                        'icon-image': 'popup',
                        'text-font': ['Lato Bold'],
                        'text-size': 23,
                    },
                    paint: {
                        'text-color': '#ffffff',
                    },
                    filter: ['all', ['>', 'rank', 0], ['<=', 'rank', 10]],
                });

                // >10 Score Label
                cm.addLayer({
                    id: 'zones_score_light',
                    type: 'symbol',
                    source: 'refactored_zones',
                    layout: {
                        'text-field': ['get', 'score'],
                        'icon-text-fit': 'both',
                        'icon-image': 'popup_light',
                        'text-font': ['Lato Bold'],
                        'text-size': 23,
                    },
                    paint: {
                        'text-color': '#ffffff',
                    },
                    filter: ['>', 'rank', 10],
                });

                // Rank Label
                cm.addLayer({
                    id: 'districts_rank',
                    type: 'symbol',
                    source: 'refactored_zones',
                    layout: {
                        'text-field': ['get', 'rank'],
                        'text-variable-anchor': ['bottom'],
                        'text-size': 30,
                        'text-radial-offset': 1,
                        'text-justify': 'auto',
                        'text-allow-overlap': true,
                    },
                    paint: {
                        'text-color': '#163746',
                        'text-halo-color': '#fff',
                        'text-halo-width': 2,
                        'text-halo-blur': 1,
                    },
                    filter: ['all', ['>', 'rank', 3], ['<=', 'rank', 10]],
                });

                // Trophy Icons for Top Three
                cm.addLayer({
                    id: 'districts_rank-top3',
                    type: 'symbol',
                    source: 'refactored_zones',
                    layout: {
                        'icon-image': [
                            'case',
                            ['==', ['get', 'rank'], 1],
                            'gold',
                            ['==', ['get', 'rank'], 2],
                            'silver',
                            'bronze',
                        ],
                        'icon-size': 1,
                        'icon-allow-overlap': true,
                        'icon-offset': [
                            'case',
                            ['==', ['get', 'rank'], 1],
                            ['literal', [0, -70]],
                            ['==', ['get', 'rank'], 2],
                            ['literal', [0, -65]],
                            ['literal', [0, -60]],
                        ],
                    },
                    paint: {
                        'text-color': '#163746',
                    },
                    filter: ['all', ['>', 'rank', 0], ['<=', 'rank', 3]],
                });

                // stations
                const stationLabels = new Set(
                    points.map((p) => p.properties.zoneId)
                );
                stationLabels.forEach((zoneId) => {
                    cm.addLayer({
                        id: `stations-${zoneId}`,
                        type: 'symbol',
                        source: 'stations',
                        layout: {
                            'text-field': ['get', 'stationName'],
                            'text-variable-anchor': [
                                'top',
                                'bottom',
                                'left',
                                'right',
                            ],
                            'text-radial-offset': 0.5,
                            'text-justify': 'auto',
                            'icon-image': 'station',
                            'icon-size': 0.5,
                            visibility: 'none',
                        },
                        filter: ['==', 'zoneId', zoneId],
                    });
                });

                // Zoom in and show stations when clicking on a zone
                cm.on('click', 'zones_fill', (e) => {
                    const { zoneId } = e.features[0].properties;
                    if (zoneId) {
                        if (
                            cm.getLayoutProperty(
                                `stations-${zoneId}`,
                                'visibility'
                            ) === 'visible'
                        ) {
                            cm.fitBounds(bbox(nswZones), {
                                padding: 50,
                            });
                            cm.setLayoutProperty(
                                `stations-${zoneId}`,
                                'visibility',
                                'none'
                            );
                        } else {
                            cm.fitBounds(bbox(e.features[0].geometry), {
                                padding: 50,
                            });
                            cm.setLayoutProperty(
                                `stations-${zoneId}`,
                                'visibility',
                                'visible'
                            );
                        }
                    }
                });
            });
        });
    });

    return (
        <Container className={S.container}>
            <Grid container direction="row" className={S.innerContainer}>
                <Grid item md={8} sm={12} xs={12} className={S.mapWrapper}>
                    <div ref={mapContainer} className={S.mapContainer} />
                </Grid>
                <Grid item md={4} sm={12} xs={12} className={S.metroContainer}>
                    <Grid container direction="column">
                        <Grid item>
                            <div className={S.title}>
                                <span>METROPOLITAN</span>
                                <span
                                    className={S.legendIcon}
                                    style={{
                                        backgroundImage: `url('${stripesImageUrl}')`,
                                    }}
                                />
                            </div>
                            {sortBy(metroZones, (zs) => zs.zone.name).map(
                                (zs) => (
                                    <DistrictListRow
                                        key={zs.zone.zone_id}
                                        rank={zs.rank}
                                        name={zs.zone.name}
                                        score={zs.score || 0}
                                    />
                                )
                            )}
                            {zonelessScore && (
                                <>
                                    <div className={S.title}>
                                        <span>NO ZONE</span>
                                        <span className={S.zoneless}>?</span>
                                    </div>
                                    <DistrictListRow
                                        rank={zonelessScore.rank}
                                        name="Other participants"
                                        score={zonelessScore.score || 0}
                                    />
                                </>
                            )}
                            <div className={S.title}>
                                <span>AUSTRALIAN CAPITAL TERRITORY</span>
                                <span
                                    className={S.legendIcon}
                                    style={{
                                        backgroundImage: `url('${redStripesImageUrl}')`,
                                    }}
                                />
                            </div>
                            <div className={S.zoneContainer}>
                                <div className={S.rankContainer} />
                                <div className={S.zoneName}>
                                    Not included in competition
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <div className={S.metroAlert}>
                            <span className={S.metroAlertIcon}>!</span>
                            <span>
                                Metro results are indicated on list only
                            </span>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

function getIcon(rank) {
    let svg;
    let alt;
    switch (rank) {
        case 1:
            svg = GoldCup;
            alt = '1st';
            break;
        case 2:
            svg = SilverCup;
            alt = '2nd';
            break;
        case 3:
            svg = BronzeCup;
            alt = '3rd';
            break;
        default:
            return '';
    }

    return <img src={svg} alt={alt} style={{ height: 40 }} />;
}

function DistrictListRow({ rank, score, name }) {
    const isTop3 = rank >= 0 && rank <= 3;
    const isTop10 = rank >= 0 && rank <= 10;
    return (
        <div className={S.zoneContainer}>
            {(() => {
                if (isTop3) {
                    return (
                        <div className={S.iconContainer}>{getIcon(rank)}</div>
                    );
                }

                if (rank > 3 && rank <= 10) {
                    return <div className={S.rankContainer}>{rank}</div>;
                }

                return <div className={S.rankContainer} />;
            })()}
            <div
                className={`${S.zoneName} ${
                    isTop3 ? classNames[rank - 1] : ''
                }`}
            >
                {name}
            </div>
            <div
                className={`${S.zoneScoreContainer} ${
                    isTop3 ? S.selected : ''
                }`}
            >
                <div
                    className={
                        isTop10 ? S.zoneScoreTriangle : S.zoneScoreTriangleLight
                    }
                />
                <div className={isTop10 ? S.zoneScore : S.zoneScoreLight}>
                    {(score || 0).toFixed(1)}
                </div>
            </div>
        </div>
    );
}

DistrictListRow.propTypes = {
    rank: PropTypes.number.isRequired,
    score: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
};

export default TopDistricts;
