import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import AWARD_LEVELS from '../../constants/award-levels';
import AlertIcon from '../../assets/images/alert.svg';
import AwardChart from './components/award-chart';
import S from './daily-best.module.scss';
import axios from '../../axios';
import { getNswDate } from '../../utils/time-utils';

function printTimeZoneDebug() {
    // eslint-disable-next-line no-console
    console.log(
        `Local time: ${new Intl.DateTimeFormat([], {
            dateStyle: 'full',
            timeStyle: 'long',
        }).format(new Date())}`
    );
    // eslint-disable-next-line no-console
    console.log(
        `UTC: ${new Intl.DateTimeFormat([], {
            timeZone: 'UTC',
            dateStyle: 'full',
            timeStyle: 'long',
        }).format(new Date())}`
    );
    // eslint-disable-next-line no-console
    console.log(
        `NSW: ${new Intl.DateTimeFormat([], {
            timeZone: 'Australia/Sydney',
            dateStyle: 'full',
            timeStyle: 'long',
        }).format(new Date())}`
    );
}

function DailyBest() {
    const blankTeam = {
        team: '--, --',
        station: '--',
        zone: '--',
    };

    const [goldTeams, setGoldTeams] = useState([blankTeam]);

    const [silverTeams, setSilverTeams] = useState([blankTeam]);

    const [bronzeTeams, setBronzeTeams] = useState([blankTeam]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        printTimeZoneDebug();

        let gold = [];
        let silver = [];
        let bronze = [];

        axios.get('/teams/dailybest').then((resp) => {
            if (resp.data.length === 0) {
                const newTeam = {
                    team: 'No sessions completed today',
                    station: '',
                    zone: '',
                };
                gold = [newTeam];
                silver = [blankTeam];
                bronze = [blankTeam];
            } else {
                for (let i = 0; i < resp.data.length; i += 1) {
                    const rank = resp.data[i].denserank;
                    if (rank === '1') {
                        gold.push(resp.data[i]);
                    } else if (rank === '2') {
                        silver.push(resp.data[i]);
                    } else if (rank === '3') {
                        bronze.push(resp.data[i]);
                    } else {
                        // eslint-disable-next-line no-console
                        console.log(
                            `There shouldn't be more than the top 3 ranking teams in the dailybest table`
                        );
                        break;
                    }
                }

                if (silver.length < 1) {
                    silver.push(blankTeam);
                }
                if (bronze.length < 1) {
                    bronze.push(blankTeam);
                }
            }

            setGoldTeams(gold);
            setSilverTeams(silver);
            setBronzeTeams(bronze);
            setLoading(false);
        });
    }, []);

    const nswToday = getNswDate(new Date());

    return (
        <Container className={S.root}>
            <Grid container className={S.infoContainer}>
                <Grid item md={3}>
                    <Typography variant="h6" color="primary">
                        BEST TEAMS TODAY
                    </Typography>
                    <Typography variant="h4" color="primary" className={S.date}>
                        {nswToday.getDate()}.{nswToday.getMonth() + 1}.
                        {nswToday.getFullYear()}
                    </Typography>
                </Grid>
                <Grid item md={5}>
                    <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        justifyContent="flex-end"
                        spacing={2}
                    >
                        <Grid item>
                            <Typography variant="h6" color="primary">
                                ABOUT
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography color="primary" className={S.text}>
                                Daily Best celebrates best performing teams,
                                rewarding them accordingly 3 bonus points for
                                the 1st place, 2 bonus points for the 2nd place
                                and 1 bonus point for the 3rd place.
                                <br />
                                <b>Updates every day at 23:59 (+11 CET)</b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={4}>
                    <Grid container direction="row" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <img src={AlertIcon} alt="alert" />
                        </Grid>
                        <Grid item>
                            <Typography color="primary" className={S.text}>
                                <b>
                                    Remember to turn your SimPad on so the data
                                    can be transferred.
                                </b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {!loading && (
                <Grid container>
                    <Grid item md={4} className={S.trophyItem}>
                        <AwardChart
                            awardLevel={AWARD_LEVELS[1]}
                            teamData={silverTeams}
                        />
                    </Grid>
                    <Grid item md={4} className={S.trophyItem}>
                        <AwardChart
                            awardLevel={AWARD_LEVELS[0]}
                            teamData={goldTeams}
                        />
                    </Grid>
                    <Grid item md={4} className={S.trophyItem}>
                        <AwardChart
                            awardLevel={AWARD_LEVELS[2]}
                            teamData={bronzeTeams}
                        />
                    </Grid>
                </Grid>
            )}
        </Container>
    );
}

export default DailyBest;
