import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Alert from '@material-ui/lab/Alert';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import { Formik } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import Snackbar from '@material-ui/core/Snackbar';
import sortBy from 'lodash/sortBy';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { v4 as uuidv4 } from 'uuid';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import S from './students.module.scss';
import AdminNavBar from '../components/admin-nav-bar';
import axios from '../../../axios';

function Superadmin() {
    const [students, setStudents] = useState([]);
    const [stations, setStations] = useState([]);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [stationLookup, setStationLookup] = useState(new Map());
    const [loading, setLoading] = useState(true);
    const [editRows, setEditRows] = useState(Immutable.Set());

    const headers = [
        { id: 'first_name', label: 'First Name' },
        { id: 'last_name', label: 'Last Name' },
        { id: 'student_studentname', label: 'Username' },
        { id: 'station_id', label: 'Station' },
        { id: 'action', label: '' },
        { id: 'delete', label: '' },
    ];

    // On initial rendering get current students and student types from db
    useEffect(async () => {
        await Promise.all([
            axios.get('/stations').then((allStationData) => {
                const stationNameLookup = new Map();
                allStationData.data.forEach((station) =>
                    stationNameLookup.set(station.station_id, station.name)
                );
                const allStations = allStationData.data;
                const sortedStations = sortBy(allStations, (s) => s.name);
                sortedStations.unshift({
                    station_id: null,
                    name: '(NO STATION)',
                });
                setStationLookup(stationNameLookup);
                setStations(sortedStations);
            }),
            axios.get('/students').then((studentsData) => {
                setStudents(
                    Immutable.fromJS(studentsData.data).sortBy((u) =>
                        u.get('last_name').toLowerCase()
                    )
                );
            }),
        ]);

        setLoading(false);
    }, []);

    // Add a new student to the list
    // Default is empty text feilds and Basic student type
    const handleAddUser = () => {
        const newId = uuidv4();
        const newStudent = Immutable.Map({
            student_id: newId,
            first_name: '',
            last_name: '',
            student_username: '',
        });

        setStudents((prev) => prev.push(newStudent));
        setEditRows((prev) => prev.add(newId));
    };

    // Function For closing the alert snackbar
    const handleClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };

    const onEditRow = (studentId) => {
        setEditRows((prev) => prev.add(studentId));
    };

    const onSave = (student) => {
        setStudents((prev) => {
            const ix = prev.findIndex(
                (s) => s.get('student_id') === student.get('student_id')
            );

            return prev.set(ix, student);
        });

        axios.put(`/students/${student.get('student_id')}`, student.toJS());

        setEditRows((prev) => prev.delete(student.get('student_id')));
    };

    const onDelete = (student) => {
        setStudents((prev) =>
            prev.filter(
                (s) => s.get('student_id') !== student.get('student_id')
            )
        );

        axios.delete(`/students/${student.get('student_id')}`);

        setEditRows((prev) => prev.delete(student.get('student_id')));
    };

    return (
        <div className={S.layout}>
            <AdminNavBar tabIx={3} />
            <div className={S.headerContainer}>
                <Typography variant="h3" className={S.header}>
                    Students
                </Typography>
                <Button
                    variant="contained"
                    className={S.bulkImportButton}
                    component={LinkBehavior}
                    to="/admin/students/bulk-import"
                >
                    Bulk Import
                </Button>
            </div>
            {loading ? (
                <CircularProgress />
            ) : (
                <div className={S.gridContainer}>
                    <div style={{ flexGrow: 1 }}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableCell key={header.id}>
                                                {header.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <Snackbar
                                        open={openSnackbar}
                                        autoHideDuration={2000}
                                        onClose={handleClose}
                                        className={S.snackbar}
                                    >
                                        <Alert
                                            onClose={handleClose}
                                            severity="success"
                                        >
                                            Saved successfully!
                                        </Alert>
                                    </Snackbar>

                                    {students.map((student) =>
                                        editRows.has(
                                            student.get('student_id')
                                        ) ? (
                                            <EditableRow
                                                key={`edit-${student.get(
                                                    'student_id'
                                                )}`}
                                                student={student}
                                                onSave={onSave}
                                                onDelete={onDelete}
                                                stations={stations}
                                            />
                                        ) : (
                                            <ReadonlyRow
                                                key={`read-${student.get(
                                                    'student_id'
                                                )}`}
                                                student={student}
                                                onEdit={onEditRow}
                                                onDelete={onDelete}
                                                stationLookup={stationLookup}
                                            />
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <Toolbar className={S.bottomToolbar}>
                            <Tooltip title="Add User">
                                <IconButton
                                    aria-label="Add User"
                                    onClick={handleAddUser}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </div>
                </div>
            )}
        </div>
    );
}

const ReadonlyRow = React.memo(
    ({ student, onEdit, onDelete, stationLookup }) => {
        const stationId = student.get('station_id');
        return (
            <TableRow hover tabIndex={-1} key={student.get('student_id')}>
                <TableCell component="th" scope="row">
                    {student.get('first_name')}
                </TableCell>
                <TableCell component="th" scope="row">
                    {student.get('last_name')}
                </TableCell>
                <TableCell component="th" scope="row">
                    {student.get('student_username')}
                </TableCell>
                <TableCell component="th" scope="row">
                    {stationId
                        ? stationLookup.get(student.get('station_id'))
                        : '(NO STATION)'}
                </TableCell>
                <TableCell className={S.iconButtonCell}>
                    <IconButton
                        aria-label="edit"
                        onClick={() => onEdit(student.get('student_id'))}
                    >
                        <EditIcon />
                    </IconButton>
                </TableCell>
                <TableCell className={S.iconButtonCell}>
                    <IconButton
                        aria-label="delete"
                        onClick={() => onDelete(student)}
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }
);

ReadonlyRow.propTypes = {
    student: PropTypes.instanceOf(Immutable.Map).isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    stationLookup: PropTypes.instanceOf(Map).isRequired,
};

const EditableRow = React.memo(
    ({ student: originalStudent, onSave, onDelete, stations }) => {
        const station =
            stations.find(
                (s) => s.station_id === originalStudent.get('station_id')
            ) || stations[0];

        return (
            <Formik
                initialValues={{
                    first_name: originalStudent.get('first_name'),
                    last_name: originalStudent.get('last_name'),
                    username: originalStudent.get('student_username'),
                    station_id: station,
                }}
                validationSchema={userSchema}
                onSubmit={(values) => {
                    const studentValues = Immutable.Map(values);
                    const newStudent = Immutable.Map({
                        student_id: originalStudent.get('student_id'),
                        first_name: studentValues.get('first_name'),
                        last_name: studentValues.get('last_name'),
                        student_username: studentValues.get('username'),
                        station_id: studentValues.get('station_id').station_id,
                    });
                    onSave(newStudent);
                }}
            >
                {(formik) => (
                    <TableRow
                        hover
                        tabIndex={-1}
                        key={originalStudent.get('student_id')}
                    >
                        <TableCell>
                            <Input
                                className={S.rowInput}
                                value={formik.values.first_name}
                                onChange={formik.handleChange}
                                placeholder="First name"
                                name="first_name"
                            />
                        </TableCell>
                        <TableCell>
                            <Input
                                className={S.rowInput}
                                value={formik.values.last_name}
                                onChange={formik.handleChange}
                                placeholder="Last name"
                                name="last_name"
                            />
                        </TableCell>
                        <TableCell>
                            <ErrorWrapper
                                error={
                                    formik.touched.username &&
                                    formik.errors.username
                                }
                            >
                                <Input
                                    className={S.rowInput}
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    placeholder="Username"
                                    name="username"
                                    error={
                                        formik.touched.username &&
                                        Boolean(formik.errors.username)
                                    }
                                />
                            </ErrorWrapper>
                        </TableCell>
                        <TableCell>
                            <Autocomplete
                                id="stations"
                                disableClearable
                                options={stations}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) =>
                                    option.station_id === value.station_id
                                }
                                style={{ width: 300 }}
                                value={formik.values.station_id}
                                onChange={(event, value) => {
                                    formik.setFieldValue('station_id', value);
                                }}
                                disabled={stations.length === 0}
                                renderInput={(params) => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                    />
                                )}
                            />
                        </TableCell>
                        <TableCell className={S.iconButtonCell}>
                            <IconButton
                                aria-label="delete"
                                onClick={formik.submitForm}
                            >
                                <DoneIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell className={S.iconButtonCell}>
                            <IconButton
                                aria-label="delete"
                                onClick={() => onDelete(originalStudent)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
            </Formik>
        );
    }
);

EditableRow.propTypes = {
    student: PropTypes.instanceOf(Immutable.Map).isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    stations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const userSchema = yup.object({
    username: yup
        .string('Enter username for user')
        .matches(/^[0-9]{8}$/, 'Username must be exactly 8 digits')
        .required('Username is required'),
});

const ErrorWrapper = ({ error, children }) => {
    if (error) {
        return <Tooltip title={error}>{children}</Tooltip>;
    }
    return children;
};

ErrorWrapper.propTypes = {
    error: PropTypes.string,
    children: PropTypes.node.isRequired,
};

ErrorWrapper.defaultProps = {
    error: null,
};

export default Superadmin;

const LinkBehavior = React.forwardRef((props, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <RouterLink ref={ref} to={props.to} {...props} />
));

LinkBehavior.propTypes = {
    to: PropTypes.string.isRequired,
};
