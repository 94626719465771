import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import { useLocation, useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import S from './login.module.scss';
import axios from '../../axios';

import { setUser } from '../../reducers/session-reducer';

import NavHeaderLogo from '../../components/nav-header/nav-header-logo';

import SignInPlaceholder from '../../assets/images/login-graphic.svg';

function Login() {
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const location = useLocation();
    const referrer =
        (location && location.state && location.state.referrer) || null;
    const history = useHistory();

    const dispatch = useDispatch();

    const handleSubmit = async (values) => {
        setSubmitting(true);
        try {
            const result = await axios.post('/auth', values);
            dispatch(setUser(result.data));
            history.push(referrer || '/');
        } catch (err) {
            if (err.response && err.response.status === 401) {
                setError('Incorrect username or password');
            } else {
                setError('An unexpected error occurred. Try again later.');
            }

            setSubmitting(false);
        }
    };

    return (
        <div className={S.container}>
            <AppBar position="relative" color="inherit">
                <Toolbar>
                    <NavHeaderLogo />
                </Toolbar>
            </AppBar>
            <main className={S.main}>
                <section className={S.leftSection}>
                    <div className={S.logoBox}>
                        <img
                            src={SignInPlaceholder}
                            alt="portal-sign-in"
                            className={S.laerdalLogo}
                        />
                        <Typography color="primary" className={S.leftTitle}>
                            NSW CPR Competition
                        </Typography>
                        <Typography
                            color="primary"
                            className={S.leftDescription}
                        >
                            STUDENT ACCESS
                        </Typography>
                    </div>
                </section>
                <Formik
                    initialValues={{
                        username: '',
                        password: '',
                        rememberLogin: false,
                    }}
                    onSubmit={handleSubmit}
                >
                    {(formik) => (
                        <section className={S.rightSection}>
                            <Paper className={S.rightContainer}>
                                <div className={S.loginBox}>
                                    <Typography
                                        variant="h4"
                                        className={S.rightTitle}
                                    >
                                        Sign in
                                    </Typography>
                                    <Typography
                                        color="primary"
                                        className={S.rightDescription}
                                    >
                                        Please provide a competition username
                                        and password to view results
                                    </Typography>
                                    <form
                                        className={S.form}
                                        autoComplete="off"
                                        onSubmit={handleSubmit}
                                    >
                                        <TextField
                                            autoFocus
                                            className={S.formField}
                                            id="username"
                                            label="Username"
                                            required
                                            value={formik.values.username}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.username &&
                                                Boolean(formik.errors.username)
                                            }
                                            disabled={submitting}
                                        />
                                        <TextField
                                            className={S.formField}
                                            id="password"
                                            label="Password"
                                            type="password"
                                            required
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={
                                                formik.touched.password &&
                                                Boolean(formik.errors.password)
                                            }
                                            disabled={submitting}
                                        />
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            className={S.rememberMeContainer}
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={
                                                            formik.values
                                                                .rememberLogin
                                                        }
                                                        name="rememberLogin"
                                                        color="secondary"
                                                        disabled={submitting}
                                                        onChange={
                                                            formik.handleChange
                                                        }
                                                    />
                                                }
                                                label="Remember me"
                                            />
                                        </Grid>
                                        <Button
                                            onClick={(ev) => {
                                                ev.preventDefault();
                                                formik.submitForm();
                                            }}
                                            type="submit"
                                            className={S.signButton}
                                            variant="contained"
                                            size="large"
                                            color="primary"
                                            disabled={submitting}
                                        >
                                            Sign in
                                        </Button>
                                        {error && (
                                            <Alert
                                                onClose={() => setError(null)}
                                                severity="error"
                                            >
                                                {error}
                                            </Alert>
                                        )}
                                    </form>
                                </div>
                            </Paper>
                        </section>
                    )}
                </Formik>
            </main>
        </div>
    );
}

export default Login;
