import React from 'react';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

function AdminNavBar({ tabIx }) {
    return (
        <Paper square>
            <Tabs
                value={tabIx}
                indicatorColor="primary"
                textColor="primary"
                aria-label="Admin Navigation"
            >
                <LinkTab label="Sessions" to="/admin/sessions" />
                <LinkTab label="Simpads" to="/admin/simpads" />
                <LinkTab label="Registration Codes" to="/admin/codes" />
                <LinkTab label="Students" to="/admin/students" />
                <LinkTab label="Users" to="/admin/users" />
            </Tabs>
        </Paper>
    );
}

AdminNavBar.propTypes = {
    tabIx: PropTypes.number.isRequired,
};

const LinkTab = (props) => (
    <Tab
        component={LinkBehavior}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
    />
);

const LinkBehavior = React.forwardRef((props, ref) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <RouterLink ref={ref} to={props.to} {...props} />
));

LinkBehavior.propTypes = {
    to: PropTypes.string.isRequired,
};

export default AdminNavBar;
