import React, { useEffect, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Papa from 'papaparse';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReplayIcon from '@material-ui/icons/Replay';
import SaveIcon from '@material-ui/icons/Save';
import { useHistory } from 'react-router-dom';
import S from './student-bulk-landing.module.scss';
import axios from '../../../axios';
import AdminNavBar from '../components/admin-nav-bar';

const headers = [
    { id: 'first_name', label: 'First Name' },
    { id: 'last_name', label: 'Last Name' },
    { id: 'student_username', label: 'Username' },
    { id: 'station_id', label: 'Station' },
    { id: 'delete', label: '' },
];

/**
 * Generate and serve the bulk student import template
 */
const getTemplate = () => {
    const csv = Papa.unparse({
        fields: ['First Name', 'Last Name', 'Username', 'Station'],
    });

    const pom = document.createElement('a');
    pom.setAttribute(
        'href',
        `data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`
    );
    pom.setAttribute('download', 'cpr-student-upload-template.csv');

    if (document.createEvent) {
        const event = document.createEvent('MouseEvents');
        event.initEvent('click', true, true);
        pom.dispatchEvent(event);
    } else {
        pom.click();
    }
};

export default function StudentBulkLanding() {
    // Track error and show error separately to avoid flickering during dialog close animation
    const [error, setError] = useState(null);
    const [showError, setShowError] = useState(false);
    const [students, setStudents] = useState([]);
    const [stationLookup, setStationLookup] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorCount, setErrorCount] = useState(0);
    const [showImportWarning, setShowImportWarning] = useState(false);

    const history = useHistory();

    useEffect(async () => {
        const allStationData = await axios.get('/stations');
        // Create a lookup from station names to station ids
        const stationNameLookup = new Map();
        allStationData.data.forEach((station) =>
            stationNameLookup.set(
                station.name.toLowerCase(),
                station.station_id
            )
        );
        setStationLookup(stationNameLookup);
        setLoading(false);
    }, []);

    const handleClose = () => {
        setShowError(false);
    };

    const handleImportWarningClose = () => {
        setShowImportWarning(false);
    };

    /**
     * Process files dropped into the dropzone
     */
    const onDrop = useCallback(
        (acceptedFiles, fileRejections) => {
            if (fileRejections.length > 0) {
                setError('Invalid bulk import template - expecting a csv.');
                setShowError(true);
                return;
            }

            if (acceptedFiles.length > 1) {
                setError('Only one template can be uploaded at a time');
                setShowError(true);
                return;
            }

            const file = acceptedFiles[0];
            Papa.parse(file, {
                delimiter: ',',
                header: true,
                complete(results) {
                    const { data } = results;

                    const validatedStudents = data
                        .map(marshalStudent)
                        .filter(filterStudentRow)
                        .map((student) =>
                            validateStudent(student, stationLookup)
                        );

                    setStudents(validatedStudents);
                    setErrorCount(
                        validatedStudents.filter(
                            (s) => s.get('errors').size > 0
                        ).length
                    );
                },
            });
        },
        [stationLookup]
    );

    const onUploadAgain = () => {
        setStudents([]);
    };

    const saveStudents = useCallback(async () => {
        await axios.post(
            '/students/bulk',
            students.filter((s) => s.get('errors').isEmpty())
        );
        history.push('/admin/students');
    }, [students]);

    const onCompleteImport = useCallback(async () => {
        if (errorCount > 0) {
            setShowImportWarning(true);
        } else {
            saveStudents();
        }
    }, [errorCount, students]);

    const onDelete = (student) => {
        const rowId = student.get('rowId');
        setStudents((currentStudents) =>
            currentStudents.filter((s) => s.get('rowId') !== rowId)
        );
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: 'text/csv, .csv',
        onDrop,
    });

    return (
        <div className={S.layout}>
            <AdminNavBar tabIx={3} />
            <div className={S.headerContainer}>
                <Typography variant="h3" className={S.header}>
                    Import Students
                </Typography>
                <Button
                    variant="contained"
                    className={S.bulkImportButton}
                    onClick={getTemplate}
                    startIcon={<GetAppIcon />}
                >
                    Download Template
                </Button>
                {students.length > 0 && (
                    <div className={S.rightButtonsContainer}>
                        <Button
                            variant="contained"
                            className={S.rightButton}
                            onClick={onUploadAgain}
                            startIcon={<ReplayIcon />}
                        >
                            Upload Another File
                        </Button>
                        <Button
                            variant="contained"
                            className={S.rightButton}
                            color="primary"
                            onClick={onCompleteImport}
                            startIcon={<SaveIcon />}
                        >
                            Complete Import
                        </Button>
                    </div>
                )}
            </div>
            {loading ? (
                <CircularProgress />
            ) : (
                <div className={S.tableContainer}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {headers.map((header) => (
                                        <TableCell key={header.id}>
                                            {header.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            {students.length === 0 ? (
                                <TableBody>
                                    <TableRow hover tabIndex={-1}>
                                        <TableCell
                                            component="th"
                                            scope="row"
                                            className={`${S.emptyCell} ${
                                                isDragActive
                                                    ? S.fileDragging
                                                    : ''
                                            }`}
                                            colSpan={6}
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...getRootProps()}
                                        >
                                            <input
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...getInputProps()}
                                            />
                                            {isDragActive ? (
                                                <div>Release to upload!</div>
                                            ) : (
                                                <>
                                                    <div>
                                                        Drop a completed
                                                        template here or{' '}
                                                    </div>
                                                    <Button variant="contained">
                                                        click to upload
                                                    </Button>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {students.map((student) => (
                                        <TableRow
                                            hover
                                            tabIndex={-1}
                                            key={student.get('rowId')}
                                        >
                                            <StudentCell
                                                component="th"
                                                scope="row"
                                                error={student.getIn([
                                                    'errors',
                                                    'first_name',
                                                ])}
                                            >
                                                {student.get('first_name')}
                                            </StudentCell>
                                            <StudentCell
                                                component="th"
                                                scope="row"
                                                error={student.getIn([
                                                    'errors',
                                                    'last_name',
                                                ])}
                                            >
                                                {student.get('last_name')}
                                            </StudentCell>
                                            <StudentCell
                                                component="th"
                                                scope="row"
                                                error={student.getIn([
                                                    'errors',
                                                    'student_username',
                                                ])}
                                            >
                                                {student.get(
                                                    'student_username'
                                                )}
                                            </StudentCell>
                                            <StudentCell
                                                component="th"
                                                scope="row"
                                                error={student.getIn([
                                                    'errors',
                                                    'station',
                                                ])}
                                            >
                                                {student.get('station')}
                                            </StudentCell>
                                            <TableCell
                                                className={S.iconButtonCell}
                                            >
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() =>
                                                        onDelete(student)
                                                    }
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </div>
            )}
            <Dialog
                open={showError}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Error</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {error}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={showImportWarning}
                onClose={handleImportWarningClose}
                aria-labelledby="warning-dialog-title"
                aria-describedby="warning-dialog-description"
            >
                <DialogTitle id="warning-dialog-title">Warning</DialogTitle>
                <DialogContent>
                    <DialogContentText id="warning-dialog-description">
                        There are {errorCount} student(s) with invalid data in
                        this bulk import. These students will not be imported.
                        <br />
                        <br />
                        Import anyway?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleImportWarningClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={saveStudents} color="primary" autoFocus>
                        Import
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

/**
 * Check if a string is null, undefined, or empty
 * @param {string} str String value to check
 * @returns {boolean} True if the string is null, undefined, or empty
 */
function isEmpty(str) {
    return str == null || str.trim().length === 0;
}

/**
 * Check if a user row has any data
 * @param {object} user Bulk import user object
 * @returns {boolean} False if user has no data
 */
function filterStudentRow(user) {
    return !(
        isEmpty(user.get('first_name')) &&
        isEmpty(user.get('last_name')) &&
        isEmpty(user.get('student_username')) &&
        isEmpty(user.get('station'))
    );
}

/**
 * Marshal a raw user from the CSV import file to a proper CPR competition student
 * @param {object} csvUser Raw csv user
 * @returns {object} Student object
 */
function marshalStudent(csvUser, ix) {
    return Immutable.Map({
        rowId: ix, // This is for tracking rows in the UI when deleting
        first_name: csvUser['First Name'],
        last_name: csvUser['Last Name'],
        student_username: csvUser.Username,
        station: csvUser.Station,
    });
}

/**
 * Check data for this student record, updating the errors object on the student if any problems are found
 * @param {Immutable.Map} student Student row
 * @param {Map} stationLookup Lookup from station name to station id
 * @returns {Immutable.Map} student record with populated errors object
 */
function validateStudent(student, stationLookup) {
    // eslint-disable-next-line no-underscore-dangle
    let _student = student;
    const errors = {};

    // eslint-disable-next-line camelcase
    const { first_name, last_name, student_username, station } =
        _student.toObject();

    if (isEmpty(first_name)) {
        errors.first_name = 'Students must have a first name';
    }

    if (isEmpty(last_name)) {
        errors.last_name = 'Students must have a last name';
    }

    const usernameRegex = new RegExp('^[0-9]{8}$');
    if (isEmpty(student_username)) {
        errors.student_username = 'Students must have username';
    } else if (!usernameRegex.test(String(student_username))) {
        errors.student_username =
            'Usernames should be only digits, and exactly 8 characters long';
    }

    if (!isEmpty(station)) {
        const stationId = stationLookup.get(station.toLowerCase());
        if (stationId) {
            _student = student.set('station_id', stationId);
        } else {
            errors.station = 'Invalid station name';
        }
    }

    return _student.set('errors', Immutable.Map(errors));
}

function StudentCell({ error, children, ...props }) {
    const hasError = !!error;
    return (
        <Tooltip
            title={hasError ? error : ''}
            classes={{
                tooltip: S.errorTooltip,
                arrow: S.errorTooltipArrow,
            }}
            arrow
        >
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <TableCell className={hasError ? S.errorCell : ''} {...props}>
                {children}
            </TableCell>
        </Tooltip>
    );
}

StudentCell.propTypes = {
    error: PropTypes.string,
    children: PropTypes.node,
};

StudentCell.defaultProps = {
    error: null,
    children: null,
};

export const exportedForTesting = {
    validateStudent,
};
