import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import S from '../../assets/styles/leaderboard.module.scss';

function LeaderBoardTotalCell({ rank, value, hasBorder, width }) {
    let totalCellContainerClasses = '';

    if (rank === 1) {
        totalCellContainerClasses = S.firstTotalContainer;
    } else if (rank === 2) {
        totalCellContainerClasses = S.secondTotalContainer;
    } else if (rank === 3) {
        totalCellContainerClasses = S.thirdTotalContainer;
    }

    return (
        <div
            className={`${S.normalCell}
                ${hasBorder ? S.borderCell : ''}`}
            style={{ width }}
        >
            <div className={`${S.totalContainer} ${totalCellContainerClasses}`}>
                <Typography
                    variant="h6"
                    color="secondary"
                    className={S.cellText}
                >
                    {value ? value.toFixed(1) : '--'}
                </Typography>
            </div>
        </div>
    );
}

LeaderBoardTotalCell.propTypes = {
    rank: PropTypes.number,
    value: PropTypes.number,
    hasBorder: PropTypes.bool,
    width: PropTypes.string,
};

LeaderBoardTotalCell.defaultProps = {
    rank: 0,
    value: null,
    hasBorder: true,
    width: '10%',
};

export default LeaderBoardTotalCell;
