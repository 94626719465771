/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState, useRef } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import bbox from '@turf/bbox';
import mapboxgl from 'mapbox-gl';
import centerOfMass from '@turf/center-of-mass';

import LeaderBoardStationHeaderRow from '../../components/leaderboard-station-header-row';
import LeaderBoardStationRow from '../../components/leaderboard-station-row';

import { addViewportBuffer } from '../../utils/mapping-utils';

import axios from '../../axios';
import nswZones from '../../data/nsw_geography.json';
import S from './top-stations.module.scss';
import './top-stations.scss';

function TopStations() {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const lng = 147.644632;
    const lat = -33.329609;
    const zoom = 5;
    const [loading, setLoading] = useState(true);
    const [topStations, setTopStations] = useState([
        { rank: 1 },
        { rank: 2 },
        { rank: 3 },
        { rank: 4 },
        { rank: 5 },
        { rank: 6 },
        { rank: 7 },
        { rank: 8 },
        { rank: 9 },
        { rank: 10 },
    ]);

    useEffect(() => {
        const stationsPromise = axios.get('/stations/top');
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/brianciomei/ckq1owper47dv17lfv5sfzp25',
            center: [lng, lat],
            zoom,
            interactive: false,
            dragPan: false,
            dragRotate: false,
            scrollZoom: false,
            doubleClickZoom: false,
        });

        const cm = map.current;

        window.addEventListener('resize', () => {
            cm.fitBounds(bbox(nswZones), {
                padding: 50,
            });
        });

        cm.on('load', async () => {
            const mTopStations = [];
            const mStations = (await stationsPromise).data;
            for (let index = 0; index < 10; index += 1) {
                if (index < mStations.length) {
                    mTopStations.push({
                        ...mStations[index],
                        rank: index + 1,
                    });
                } else {
                    mTopStations.push({
                        rank: index + 1,
                    });
                }
            }
            setTopStations(mTopStations);

            const images = [
                {
                    id: 'station',
                    url: `${process.env.PUBLIC_URL}/station-polygon.png`,
                },
                {
                    id: 'gold',
                    url: `${process.env.PUBLIC_URL}/gold-marker.png`,
                },
                {
                    id: 'silver',
                    url: `${process.env.PUBLIC_URL}/silver-marker.png`,
                },
                {
                    id: 'bronze',
                    url: `${process.env.PUBLIC_URL}/bronze-marker.png`,
                },
            ];

            const imagePromises = images.map(
                (img) =>
                    new Promise((resolve) => {
                        cm.loadImage(img.url, (error, res) => {
                            cm.addImage(img.id, res);
                            resolve();
                        });
                    })
            );

            imagePromises.push(
                cm.loadImage(
                    `${process.env.PUBLIC_URL}/blue_rectangle.png`,
                    (error, res) => {
                        cm.addImage('blue_rectangle', res, {
                            stretchX: [[4, 27]],
                            stretchY: [[4, 28]],
                            content: [5, 5, 26, 27],
                            pixelRatio: 2,
                        });
                    }
                )
            );
            Promise.all(imagePromises).then(async () => {
                cm.fitBounds(bbox(nswZones), {
                    padding: 50,
                });

                cm.addSource('zones', {
                    type: 'geojson',
                    data: nswZones,
                });

                const zones = [];
                nswZones.features.forEach((zone) => {
                    zones.push({
                        type: 'Feature',
                        geometry: centerOfMass(zone.geometry).geometry,
                        properties: {
                            id: zone.properties.name,
                            zoneName: zone.properties.name,
                        },
                    });
                });

                cm.addSource('refactored_zones', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: zones,
                    },
                });

                // Add a black outline around the polygon.
                cm.addLayer({
                    id: 'zones_outline',
                    type: 'line',
                    source: 'zones',
                    layout: {},
                    paint: {
                        'line-color': '#000',
                        'line-width': 2,
                    },
                });

                // Name Label
                cm.addLayer({
                    id: 'zones_name',
                    type: 'symbol',
                    source: 'refactored_zones',
                    layout: {
                        'text-field': ['get', 'zoneName'],
                        'text-variable-anchor': ['top'],
                        'text-radial-offset': 1,
                        'text-font': ['Lato Bold'],
                        'text-justify': 'auto',
                    },
                    paint: {
                        'text-color': '#000000',
                        'text-halo-color': '#fff',
                        'text-halo-width': 2,
                        'text-halo-blur': 1,
                    },
                    filter: [
                        'all',
                        ['!=', 'zoneName', '!ACT!'],
                        ['!=', 'zoneName', '!Metropolitan!'],
                    ],
                });

                const points = mTopStations
                    .filter((s) => s.station_id)
                    .map((s) => {
                        const point = JSON.parse(s.coordinates);
                        return {
                            type: 'Feature',
                            geometry: point,
                            properties: {
                                stationName: s.name,
                                rank: s.rank,
                            },
                        };
                    });

                cm.addSource('stations', {
                    type: 'geojson',
                    data: {
                        type: 'FeatureCollection',
                        features: points,
                    },
                });

                cm.addLayer({
                    id: 'stations_circle_bronze',
                    type: 'symbol',
                    source: 'stations',
                    layout: {
                        'icon-image': 'bronze',
                        'icon-size': 1,
                        'icon-allow-overlap': true,
                        'icon-offset': ['literal', [0, -20]],
                    },
                    filter: ['==', 'rank', 3],
                });

                cm.addLayer({
                    id: 'stations_circle_silver',
                    type: 'symbol',
                    source: 'stations',
                    layout: {
                        'icon-image': 'silver',
                        'icon-size': 1,
                        'icon-allow-overlap': true,
                        'icon-offset': ['literal', [0, -25]],
                    },
                    filter: ['==', 'rank', 2],
                });

                cm.addLayer({
                    id: 'stations_circle_gold',
                    type: 'symbol',
                    source: 'stations',
                    layout: {
                        'icon-image': 'gold',
                        'icon-size': 1,
                        'icon-allow-overlap': true,
                        'icon-offset': ['literal', [0, -30]],
                    },
                    filter: ['==', 'rank', 1],
                });

                cm.addLayer({
                    id: 'stations_rank-top3',
                    type: 'symbol',
                    source: 'stations',
                    layout: {
                        'text-field': ['get', 'stationName'],
                        'text-radial-offset': 1,
                        'text-variable-anchor': [
                            'top',
                            'right',
                            'left',
                            'center',
                        ],
                        'text-font': ['Lato Bold'],
                    },
                    paint: {
                        'text-color': [
                            'case',
                            ['==', ['get', 'rank'], 1],
                            '#B38F00',
                            ['==', ['get', 'rank'], 2],
                            '#767676',
                            ['==', ['get', 'rank'], 3],
                            '#936E4D',
                            'transparent',
                        ],
                        'text-halo-color': '#fff',
                        'text-halo-width': 2,
                        'text-halo-blur': 1,
                    },
                    filter: ['all', ['>', 'rank', 0], ['<=', 'rank', 3]],
                });

                const defaultFilter = ['==', 'true', 'false'];

                cm.addLayer({
                    id: 'station_names',
                    type: 'symbol',
                    source: 'stations',
                    layout: {
                        'text-field': ['get', 'stationName'],
                        'icon-text-fit': 'both',
                        'icon-image': 'blue_rectangle',
                        'text-size': 15,
                        'text-font': ['Lato Bold'],
                        'text-variable-anchor': [
                            'left',
                            'right',
                            'top',
                            'bottom',
                            'center',
                        ],
                        'text-radial-offset': 2,
                        'text-justify': 'auto',
                    },
                    paint: {
                        'text-color': '#333333',
                    },
                    filter: defaultFilter,
                });

                cm.addLayer({
                    id: 'station_circles',
                    type: 'circle',
                    source: 'stations',
                    paint: {
                        'circle-radius': 18,
                        'circle-color': 'white',
                        'circle-stroke-color': '#5062A5',
                        'circle-stroke-width': 2,
                    },
                    filter: defaultFilter,
                });

                cm.addLayer({
                    id: 'station_ranks',
                    type: 'symbol',
                    source: 'stations',
                    layout: {
                        'text-field': ['get', 'rank'],
                        'text-size': 24,
                    },
                    paint: {
                        'text-color': '#5F73BA',
                    },
                    filter: defaultFilter,
                });

                addViewportBuffer(cm);

                // After the initial label fade-in, make transitions instant
                setTimeout(() => {
                    cm._fadeDuration = 0;
                }, 1000);

                setLoading(false);
            });
        });
    }, []);

    const handleHover = (station) => {
        if (map.current && !loading) {
            if (station && station.station_id && station.rank > 3) {
                const hoverOn = (layerId) => {
                    map.current.setLayoutProperty(
                        layerId,
                        'visibility',
                        'visible'
                    );
                    map.current.setFilter(layerId, [
                        '==',
                        station.name,
                        ['get', 'stationName'],
                    ]);
                };
                hoverOn('station_ranks');
                hoverOn('station_names');
                hoverOn('station_circles');
            } else {
                const hoverOff = (layerId) => {
                    map.current.setLayoutProperty(
                        layerId,
                        'visibility',
                        'none'
                    );
                };
                hoverOff('station_ranks');
                hoverOff('station_names');
                hoverOff('station_circles');
            }
        }
    };

    return (
        <Container className={S.container}>
            <Grid container direction="row" className={S.innerContainer}>
                <Grid item md={7} sm={12} xs={12} className={S.mapWrapper}>
                    <div ref={mapContainer} className={S.mapContainer} />
                </Grid>
                <Grid item md={5} sm={12} xs={12} className={S.tableContainer}>
                    <LeaderBoardStationHeaderRow />
                    {topStations.map((station, index) => (
                        <LeaderBoardStationRow
                            key={`station-${station.station_id || index}`}
                            loading={loading}
                            station={station}
                            hasBorder={index !== topStations.length - 1}
                            onHover={handleHover}
                        />
                    ))}
                </Grid>
            </Grid>
        </Container>
    );
}

export default TopStations;
