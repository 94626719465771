import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import S from '../../assets/styles/leaderboard.module.scss';

function LeaderBoardAlertIcon({ numAttempts }) {
    return (
        <div className={`${S.normalCell} ${S.alert}`}>
            {numAttempts < 3 && (
                <Tooltip
                    classes={{
                        tooltip: S.alertTooltip,
                    }}
                    className={`${S.alert} ${S.alertHover}`}
                    title="Teams must complete at least 3 attempts to be qualified for the grand prize."
                    enterDelay={0}
                    placement="bottom-start"
                >
                    <span className={S.alertIcon}>!</span>
                </Tooltip>
            )}
        </div>
    );
}

LeaderBoardAlertIcon.propTypes = {
    numAttempts: PropTypes.number,
};

LeaderBoardAlertIcon.defaultProps = {
    numAttempts: null,
};

export default LeaderBoardAlertIcon;
