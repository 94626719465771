/* eslint-disable no-underscore-dangle */
/**
 * Add an invisible bounding box to the map to trick mapbox into drawing
 * labels inside the viewport
 * @param {object} map Mapbox map instance
 */
function addViewportBuffer(map) {
    const viewport = map.getBounds();

    map.addSource('viewport-line', {
        type: 'geojson',
        data: {
            type: 'Feature',
            properties: {},
            geometry: {
                type: 'LineString',
                coordinates: [
                    [viewport._sw.lng, viewport._sw.lat],
                    [viewport._sw.lng, viewport._ne.lat],
                    [viewport._ne.lng, viewport._ne.lat],
                    [viewport._ne.lng, viewport._sw.lat],
                    [viewport._sw.lng, viewport._sw.lat],
                ],
            },
        },
    });

    // Create an invisible border around the viewport
    // Any labels that collide with this border will attempt to redraw away
    // from the border (and therefore into view)
    // https://github.com/mapbox/mapbox-gl-js/issues/6432#issuecomment-384423238
    const width = 125;
    const data = new Uint8Array(width * width * 4);
    map.addImage('pixel', {
        width,
        height: width,
        data,
    });

    map.addLayer({
        id: 'viewport-line-symbols',
        type: 'symbol',
        source: 'viewport-line',
        layout: {
            'icon-image': 'pixel',
            'symbol-placement': 'line',
            'symbol-spacing': 5,
        },
    });

    // Uncomment this to visualize what this is doing
    // map.showCollisionBoxes = true;
}

module.exports = {
    addViewportBuffer,
};
