import React from 'react';
import Typography from '@material-ui/core/Typography';
import LaerdalLogo from './laerdal-logo';
import LogoDivider from './logo-divider';
import S from './nav-header.module.scss';

export default function NavHeaderLogo() {
    return (
        <>
            <LaerdalLogo />
            <LogoDivider />
            <Typography variant="h6" className={S.navTitle}>
                NSW CPR Competition
            </Typography>
        </>
    );
}
