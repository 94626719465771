import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { v4 as uuidv4 } from 'uuid';
import Immutable from 'immutable';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Formik } from 'formik';
import * as yup from 'yup';
import S from './superadmin.module.scss';
import AdminNavBar from '../components/admin-nav-bar';

import axios from '../../../axios';

function Superadmin() {
    const [users, setUsers] = useState([]);
    const [userTypes, setUserTypes] = useState([]);
    const [typeLookup, setTypeLookup] = useState(new Map());
    const [loading, setLoading] = useState(true);
    const [editRows, setEditRows] = useState(Immutable.Set());

    const headers = [
        { id: 'first_name', label: 'First Name' },
        { id: 'last_name', label: 'Last Name' },
        { id: 'username', label: 'Username' },
        { id: 'user_type_id', label: 'User Type' },
        { id: 'action', label: '' },
        { id: 'delete', label: '' },
    ];

    // On initial rendering get current users and user types from db
    useEffect(async () => {
        await Promise.all([
            axios.get('/users/types').then((allTypesData) => {
                const userTypeLookup = new Map();
                allTypesData.data.forEach((type) =>
                    userTypeLookup.set(type.user_type_id, type.description)
                );
                setTypeLookup(userTypeLookup);
                setUserTypes(allTypesData.data);
            }),
            axios.get('/users/').then((usersData) => {
                setUsers(
                    Immutable.fromJS(usersData.data).sortBy((u) =>
                        u.get('last_name')
                    )
                );
            }),
        ]);

        setLoading(false);
    }, []);

    // Add a new user to the list
    // Default is empty text feilds and Basic user type
    const handleAddUser = () => {
        const newId = uuidv4();
        const newTypeId = userTypes[0].user_type_id;
        const newUser = Immutable.Map({
            isNew: true,
            user_id: newId,
            first_name: '',
            last_name: '',
            username: '',
            user_type_id: newTypeId,
        });

        setUsers((prev) => prev.push(newUser));
        setEditRows((prev) => prev.add(newId));
    };

    const onEditRow = (userId) => {
        setEditRows((prev) => prev.add(userId));
    };

    const onSave = (user, password) => {
        setUsers((prev) => {
            const ix = prev.findIndex(
                (u) => u.get('user_id') === user.get('user_id')
            );
            return prev.set(ix, user);
        });

        const req = { user: user.toJS(), password };

        delete req.user.isNew;

        axios.put(`/users/${user.get('user_id')}`, req);

        setEditRows((prev) => prev.delete(user.get('user_id')));
    };

    const onDelete = (user) => {
        setUsers((prev) =>
            prev.filter((u) => u.get('user_id') !== user.get('user_id'))
        );

        axios.delete(`/users/${user.get('user_id')}`);

        setEditRows((prev) => prev.delete(user.get('user_id')));
    };

    return (
        <div className={S.layout}>
            <AdminNavBar tabIx={4} />
            <Typography variant="h3" className={S.header}>
                Users
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : (
                <div className={S.gridContainer}>
                    <div style={{ flexGrow: 1 }}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {headers.map((header) => (
                                            <TableCell key={header.id}>
                                                {header.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {users.map((user) =>
                                        editRows.has(user.get('user_id')) ? (
                                            <EditableRow
                                                key={`edit-${user.get(
                                                    'user_id'
                                                )}`}
                                                user={user}
                                                onSave={onSave}
                                                onDelete={onDelete}
                                                userTypes={userTypes}
                                            />
                                        ) : (
                                            <ReadonlyRow
                                                key={`read-${user.get(
                                                    'user_id'
                                                )}`}
                                                user={user}
                                                onEdit={onEditRow}
                                                onDelete={onDelete}
                                                typeLookup={typeLookup}
                                            />
                                        )
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Toolbar className={S.bottomToolbar}>
                            <Tooltip title="Add User">
                                <IconButton
                                    aria-label="Add User"
                                    onClick={handleAddUser}
                                >
                                    <AddIcon />
                                </IconButton>
                            </Tooltip>
                        </Toolbar>
                    </div>
                </div>
            )}
        </div>
    );
}

const ReadonlyRow = React.memo(({ user, onEdit, onDelete, typeLookup }) => (
    <TableRow hover tabIndex={-1} key={user.get('user_id')}>
        <TableCell component="th" scope="row">
            {user.get('first_name')}
        </TableCell>
        <TableCell component="th" scope="row">
            {user.get('last_name')}
        </TableCell>
        <TableCell component="th" scope="row">
            {user.get('username')}
        </TableCell>
        <TableCell component="th" scope="row">
            {typeLookup.get(user.get('user_type_id'))}
        </TableCell>
        <TableCell className={S.iconButtonCell}>
            <IconButton
                aria-label="edit"
                onClick={() => onEdit(user.get('user_id'))}
            >
                <EditIcon />
            </IconButton>
        </TableCell>
        <TableCell className={S.iconButtonCell}>
            <IconButton aria-label="delete" onClick={() => onDelete(user)}>
                <DeleteIcon />
            </IconButton>
        </TableCell>
    </TableRow>
));

ReadonlyRow.propTypes = {
    user: PropTypes.instanceOf(Immutable.Map).isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    typeLookup: PropTypes.instanceOf(Map).isRequired,
};

const EditableRow = React.memo(
    ({ user: originalUser, onSave, onDelete, userTypes }) => {
        const isNewUser = originalUser.get('isNew');

        const initialValues = originalUser.toJS();
        initialValues.user_type = userTypes.find(
            (ut) => ut.user_type_id === initialValues.user_type_id
        );
        delete initialValues.user_type_id;
        initialValues.password = '';

        const [isChangingPassword, setIsChangingPassword] = useState(isNewUser);

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={userSchema}
                onSubmit={(values) => {
                    const { password } = values;
                    let user = Immutable.Map(values);

                    user = user.set(
                        'user_type_id',
                        values.user_type.user_type_id
                    );
                    user = user.delete('user_type').delete('password');
                    onSave(Immutable.Map(user), password);
                }}
            >
                {(formik) => (
                    <TableRow hover tabIndex={-1}>
                        <TableCell>
                            <ErrorWrapper
                                error={
                                    formik.touched.first_name &&
                                    formik.errors.first_name
                                }
                            >
                                <TextField
                                    autoFocus
                                    name="first_name"
                                    className={S.rowInput}
                                    value={formik.values.first_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.first_name &&
                                        Boolean(formik.errors.first_name)
                                    }
                                    placeholder="First name"
                                />
                            </ErrorWrapper>
                        </TableCell>
                        <TableCell>
                            <ErrorWrapper
                                error={
                                    formik.touched.last_name &&
                                    formik.errors.last_name
                                }
                            >
                                <TextField
                                    name="last_name"
                                    className={S.rowInput}
                                    value={formik.values.last_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.last_name &&
                                        Boolean(formik.errors.last_name)
                                    }
                                    placeholder="Last name"
                                />
                            </ErrorWrapper>
                        </TableCell>
                        <TableCell>
                            <ErrorWrapper
                                error={
                                    formik.touched.username &&
                                    formik.errors.username
                                }
                            >
                                <TextField
                                    name="username"
                                    className={S.rowInput}
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={
                                        formik.touched.username &&
                                        Boolean(formik.errors.username)
                                    }
                                    placeholder="Username"
                                />
                            </ErrorWrapper>
                        </TableCell>
                        <TableCell>
                            <Autocomplete
                                name="user_type"
                                disableClearable
                                options={userTypes}
                                getOptionLabel={(option) => option.description}
                                getOptionSelected={(option, value) =>
                                    option.user_type_id === value.user_type_id
                                }
                                onChange={(ev, val) => {
                                    formik.setFieldValue('user_type', val);
                                }}
                                style={{ width: 300 }}
                                value={formik.values.user_type}
                                disabled={userTypes.length === 0}
                                renderInput={(params) => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                    />
                                )}
                            />
                        </TableCell>
                        {isChangingPassword ? (
                            <TableCell>
                                <ErrorWrapper
                                    error={
                                        formik.touched.password &&
                                        formik.errors.password
                                    }
                                >
                                    <TextField
                                        name="password"
                                        className={S.rowInput}
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={
                                            formik.touched.password &&
                                            Boolean(formik.errors.password)
                                        }
                                        placeholder="Password"
                                    />
                                </ErrorWrapper>
                            </TableCell>
                        ) : (
                            <TableCell className={S.buttonCell}>
                                <Button
                                    className={S.changePasswordButton}
                                    onClick={() => setIsChangingPassword(true)}
                                >
                                    Change Password
                                </Button>
                            </TableCell>
                        )}
                        <TableCell className={S.iconButtonCell}>
                            <IconButton
                                aria-label="delete"
                                onClick={() => formik.submitForm()}
                            >
                                <DoneIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell className={S.iconButtonCell}>
                            <IconButton
                                aria-label="delete"
                                onClick={() => onDelete(originalUser)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                )}
            </Formik>
        );
    }
);

EditableRow.propTypes = {
    user: PropTypes.instanceOf(Immutable.Map).isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    userTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const userSchema = yup.object({
    first_name: yup
        .string('Enter user first name')
        .required('First name is required'),
    last_name: yup.string('Enter last name').required('Last name is required'),
    username: yup
        .string('Enter username for user')
        .required('Username is required'),
    isNew: yup.boolean(),
    password: yup.string().when('isNew', {
        is: true,
        then: yup.string('Enter password').required('A password is required'),
        otherwise: yup.string(),
    }),
});

const ErrorWrapper = ({ error, children }) => {
    if (error) {
        return <Tooltip title={error}>{children}</Tooltip>;
    }
    return children;
};

ErrorWrapper.propTypes = {
    error: PropTypes.string,
    children: PropTypes.node.isRequired,
};

ErrorWrapper.defaultProps = {
    error: null,
};

export default Superadmin;
