const { zonedTimeToUtc, utcToZonedTime } = require('date-fns-tz');

/**
 * Convert a NSW datetime to UTC time.
 * Javascript timezones suck. If you pass in a time as seen on the clock in Australia, this returns the corresponding UTC time.
 * When doing console.log and manipulating dates they ALWAYS show in the local time zone so be careful
 * @param {date} date date as seen on the clock in NSW, Australia (i.e. IANA Australia/Sydney)
 * @returns {date} corresponding time in UTC
 */
function utcToNsw(date) {
    return zonedTimeToUtc(date, 'Australia/Sydney');
}

/**
 * For a given local time, get the start-of-day based on NSW time.
 * If you pass in 11 PM EST on August 5th (which is 1 PM AEST on August 6th), this returns 8/6 @ 0:0:0
 * @param {date} date Local time to check against
 * @returns The date component of the corresponding time in NSW
 */
function getNswDate(date) {
    const timeNowNsw = utcToZonedTime(date, 'Australia/Sydney');
    timeNowNsw.setHours(0, 0, 0, 0);
    return timeNowNsw;
}

module.exports = {
    utcToNsw,
    getNswDate,
};
