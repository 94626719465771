import axios from 'axios';
import { logout } from './reducers/session-reducer';
import { store } from './reducers/reducers';

const configuredAxios = axios.create({
    baseURL: process.env.API_ROOT,
    withCredentials: true,
});

configuredAxios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response) {
            const { status, data } = error.response;

            // User logged out, session expired, etc
            if (status === 401 && data && data.error === 'INVALID_TOKEN') {
                store.dispatch(logout());
                window.location.reload();
            }
        }

        return Promise.reject(error);
    }
);

export default configuredAxios;
