import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { DataGrid } from '@material-ui/data-grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import sortBy from 'lodash/sortBy';
import isNil from 'lodash/isNil';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import axios from '../../../axios';
import S from './simpad-codes.module.scss';
import AdminNavBar from '../components/admin-nav-bar';

const stationNameLookup = new Map();

const columns = [
    {
        field: 'registration_code',
        headerName: 'Registration Code',
        width: 200,
    },
    {
        field: 'station_id',
        headerName: 'Station',
        flex: 1,
        valueGetter: (params) =>
            params.row.station_id
                ? stationNameLookup.get(params.row.station_id)
                : '(NO STATION)',
    },
    {
        field: 'id',
        headerName: ' ',
    },
];

function SimpadCodes() {
    const [stations, setStations] = useState([]);
    const [selectedStation, setSelectedStation] = useState(null);
    const [codes, setCodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [snackbarCode, setSnackbarCode] = useState(null);

    useEffect(async () => {
        await Promise.all([
            axios.get('/stations').then((allStationData) => {
                const sortedStations = sortBy(
                    allStationData.data,
                    (s) => s.name
                );
                sortedStations.unshift({
                    station_id: null,
                    name: '(NO STATION)',
                });

                setStations(sortedStations);

                allStationData.data.forEach((station) =>
                    stationNameLookup.set(station.station_id, station.name)
                );
            }),
            axios.get('/simpads/codes').then((allCodesData) => {
                const allCodes = allCodesData.data;
                allCodes.forEach((c) => {
                    // eslint-disable-next-line no-param-reassign
                    c.id = c.registration_code;
                });
                setCodes(sortBy(allCodes, (s) => s.registration_code));
            }),
        ]);

        setLoading(false);
        // ;
    }, []);

    const addCode = () => {
        const code = generateCode(5);
        const newRegistrationCode = {
            registration_code: code,
            station_id: selectedStation.station_id,
        };

        setCodes([
            ...codes,
            {
                // All rows require an ID
                id: code,
                ...newRegistrationCode,
            },
        ]);

        axios.put('/simpads/codes', newRegistrationCode);
        setSnackbarCode(code);
    };

    const onDeleteCode = (code) => {
        setCodes((prevCodes) =>
            prevCodes.filter((c) => c.registration_code !== code)
        );

        axios.delete('/simpads/codes', {
            data: {
                registration_code: code,
            },
        });
    };

    columns[2].renderCell = (params) => (
        <IconButton
            onClick={() => onDeleteCode(params.row.registration_code)}
            aria-label="delete"
            component="span"
            className={S.deleteButton}
        >
            <Delete />
        </IconButton>
    );

    const onStationSelected = (event, value) => {
        setSelectedStation(value);
    };

    const onSnackbarClose = () => {
        setSnackbarCode(null);
    };

    return (
        <div className={S.layout}>
            <AdminNavBar tabIx={2} />
            <Typography variant="h3" className={S.header}>
                SimPad Registration Codes
            </Typography>
            <div className={S.addCodeContainer}>
                <Autocomplete
                    disableClearable
                    id="stations"
                    options={stations}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                        option.station_id === value.station_id
                    }
                    style={{ width: 300 }}
                    value={selectedStation}
                    onChange={onStationSelected}
                    disabled={stations.length === 0}
                    renderInput={(params) => (
                        <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Station"
                            variant="outlined"
                        />
                    )}
                />
                <Button
                    variant="outlined"
                    color="primary"
                    disabled={loading || !selectedStation}
                    onClick={addCode}
                >
                    Add Registration Code
                </Button>
            </div>
            <div className={S.gridContainer}>
                <div style={{ flexGrow: 1 }}>
                    <DataGrid
                        rows={loading ? [] : codes}
                        columns={columns}
                        disableColumnSelector
                    />
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={!isNil(snackbarCode)}
                message={`Created registration code ${snackbarCode}`}
                action={
                    <>
                        <Button
                            color="secondary"
                            size="small"
                            onClick={onSnackbarClose}
                        >
                            UNDO
                        </Button>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={onSnackbarClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </>
                }
            />
        </div>
    );
}

const alphanumericChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
function generateCode(length) {
    let result = '';
    for (let i = length; i > 0; i -= 1)
        result +=
            alphanumericChars[
                Math.floor(Math.random() * alphanumericChars.length)
            ];
    return result;
}

export default SimpadCodes;
