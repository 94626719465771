import { number, string } from 'prop-types';

const Station = {
    station_id: string,
    name: string,
    address: string,
    zone_id: string,
    coordinates: string,
    total: number,
    rank: number,
};

export default Station;
