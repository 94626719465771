import React, { useEffect, useState } from 'react';
import orderBy from 'lodash/orderBy';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Delete from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import S from './sessions.module.scss';
import axios from '../../../axios';
import AdminNavBar from '../components/admin-nav-bar';

function Sessions() {
    const [stationLookup, setStationLookup] = useState(new Map());
    const [sessions, setSessions] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
        await Promise.all([
            axios.get('/stations').then((allStationData) => {
                const stationNameLookup = new Map();
                allStationData.data.forEach((station) =>
                    stationNameLookup.set(station.station_id, station.name)
                );
                setStationLookup(stationNameLookup);
            }),
            axios.get('/simpads/sessions/latest').then((allSessionsData) => {
                setSessions(
                    orderBy(allSessionsData.data, ['start_ts'], ['desc'])
                );
            }),
        ]);

        setLoading(false);
        // ;
    }, []);

    const onDeleteSession = (sessionId) => {
        setSessions((prevSessions) =>
            prevSessions.filter((s) => s.session_id !== sessionId)
        );

        axios.delete('/simpads/sessions', {
            data: {
                sessionId,
            },
        });
    };

    return (
        <div className={S.layout}>
            <AdminNavBar tabIx={0} />
            <Typography variant="h3" className={S.header}>
                Latest Sessions
            </Typography>
            {loading ? (
                <CircularProgress />
            ) : (
                <div className={S.gridContainer}>
                    <div style={{ flexGrow: 1 }}>
                        <TableContainer component={Paper}>
                            <Table aria-label="Latest sessions">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Students</TableCell>
                                        <TableCell align="right">
                                            Start Time
                                        </TableCell>
                                        <TableCell align="right">
                                            End Time
                                        </TableCell>
                                        <TableCell align="right">
                                            Station
                                        </TableCell>
                                        <TableCell align="right">
                                            CPR Score
                                        </TableCell>
                                        <TableCell align="right">CCF</TableCell>
                                        <TableCell align="right" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sessions.map((session) => (
                                        <TableRow key={session.session_id}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {session.students
                                                    .map(
                                                        (s) =>
                                                            `${s.first_name.substring(
                                                                0,
                                                                1
                                                            )}. ${s.last_name}`
                                                    )
                                                    .join(', ')}
                                            </TableCell>
                                            <TableCell align="right">
                                                {session.start_ts}
                                            </TableCell>
                                            <TableCell align="right">
                                                {session.end_ts}
                                            </TableCell>
                                            <TableCell align="right">
                                                {stationLookup.get(
                                                    session.station_id
                                                )}
                                            </TableCell>
                                            <TableCell align="right">
                                                {session.cpr_overall_score}
                                            </TableCell>
                                            <TableCell align="right">
                                                {
                                                    session.chest_compression_fraction
                                                }
                                            </TableCell>
                                            <TableCell
                                                align="right"
                                                width="48px"
                                            >
                                                <IconButton
                                                    onClick={() =>
                                                        onDeleteSession(
                                                            session.session_id
                                                        )
                                                    }
                                                    aria-label="delete"
                                                    component="span"
                                                    className={S.deleteButton}
                                                >
                                                    <Delete />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Sessions;
